<template>
    <div class="card shadow-sm">
        <div class="card-header">
            <strong>{{ carrier.name }}</strong>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item"
                v-for="service in carrier.services" :key="'carrier-service-' + service.name">
                <router-link :to="{ name: service.route }" class="text-dark">
                    {{ service.name }}
                </router-link>
                <font-awesome-icon icon="circle" pull="right"
                                   :title="getCarrierTitle(service.active)"
                                   :class="getCarrierActiveClass(service.active)"/>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "ComponentCarrierCard",
    props: {
        carrier: {
            type: Object
        }
    },
    methods: {
        getCarrierActiveClass(active) {
            return {
                'text-muted': !active,
                'text-success': active
            }
        },
        getCarrierTitle(active) {
            if (active) {
                return this.$tc('active');
            }

            return this.$tc('inactive');
        }
    }
}
</script>

<style scoped>

</style>