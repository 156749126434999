<template>
    <div>
        <layout-full-width :title="$tc('carrier', 2)">

            <div class="row">
                <div class="col-12">
                    <form @submit.prevent="console.log('foo')">
                        <div class="form-group">
                            <label for="search-carriers" class="sr-only">{{ $tc('search') }}</label>
                            <input id="search-carriers" class="form-control" type="search"  :placeholder="$tc('search')">
                        </div>
                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div id="cards-containers" class="card-columns">
                        <component-carrier-card :carrier="carrier" v-for="carrier in carriers" :key="'carrier-' + carrier.name"/>
                    </div>
                </div>
            </div>

        </layout-full-width>
    </div>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentCarrierCard from "@/components/ComponentCarrierCard";

export default {
    name: "PageCarrier",
    components: { ComponentCarrierCard, LayoutFullWidth },
    data() {
        return {
            carriers: [
                {
                    name: 'DPDHL Group',
                    logo: 'dpdhl.png',
                    services: [
                        {
                            name: 'Portokasse',
                            route: 'settings-carriers-dpdhl-portokasse',
                            active: true,
                        },
                        {
                            name: 'Internetmarke',
                            route: 'settings-carriers-dpdhl-internetmarke',
                            active: true,
                        },
                        {
                            name: 'PacketPlus',
                            route: 'settings-carriers-dpdhl-packet-plus',
                            active: true,
                        },
                        {
                            name: 'DHL Location Finder',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'DHL Shipment Tracking',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'DHL Deutsche Post International',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'DHL eCommerce Solutions Europe',
                            route: '',
                            active: false,
                        },
                    ]
                },
                {
                    name: 'UPS',
                    logo: '',
                    services: [
                        {
                            name: 'Address Validation API',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'Address Validation Street Level API',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'Gemini™ APIs',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'Locator API and Plug-in',
                            route: '',
                            active: false,
                        },
                        {
                            name: 'Paperless Document API',
                            route: '',
                            active: false,
                        }
                    ]
                },
                {
                    name: 'TNT',
                    logo: '',
                    services: [
                        {
                            name: 'Routing Labels',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Shipping',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Pricing',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Tracking',
                            route: '',
                            active: false
                        }
                    ]
                },
                {
                    name: 'DPD (Germany)',
                    logo: '',
                    services: [
                        {
                            name: 'Login Service 2.0',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Shipment Service 4.3',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Parcel Life Cycle Service 2.0',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Parcel Shop Finder Service 5.0',
                            route: '',
                            active: false
                        }
                    ],
                },
                {
                    name: 'DPD (Croatia)',
                    logo: '',
                    services: [
                        {
                            name: 'Login Service 2.0',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Shipment Service 4.3',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Parcel Life Cycle Service 2.0',
                            route: '',
                            active: false
                        },
                        {
                            name: 'Parcel Shop Finder Service 5.0',
                            route: '',
                            active: false
                        }
                    ],
                }
            ]
        }
    },
    methods: {
    }
}
</script>
<style scoped lang="scss">
    @media (max-width: 768px) {
        #cards-containers {
            column-count: 1;
        }
    }
</style>